// General
$color-white: #ffffff;
$color-black: #000000;
$color-grey: #525252;
$color-transparent: rgba(0, 0, 0, 0);

// Brand colors
$color-brand-primary: #1e9175;
$color-brand-secondary: #bd1227;
$color-brand-primary-lighten: lighten($color-brand-primary, 50%);
$color-brand-secondary-lighten: lighten($color-brand-secondary, 50%);

$color-greyscale: (
  'default': #f4f4f4,
  'light': #f2f3f4,
  'regular': #a5abbf,
  'dark': #4e525f,
);

$color-greenscale: (
  'light': #8ec8ba,
  'default': #1e9175,
);

$color-redscale: (
  'light': #de8893,
  'default': #bd1227,
);

$tile-background: #ffffff;
