@import '../../styles/core';

.topbar-container {
  display: flex;
  justify-content: center;
  height: 80px;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.15);
  z-index: 1;
  position: relative;
  background-color: $color-white;
}

.topbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
}

.topbar-first {
  display: flex;
}

.topbar-logo {
  padding-top: 8px;
  margin-right: 50px;
  @media (max-width: $breakpoint-sm) {
    margin-right: 20px;
    width: 120px;
  }
}

.topbar-nav__container {
  display: flex;
  align-items: center;
}

.topbar-nav {
  display: flex;

  // @media (min-width: $breakpoint-lg) {
  //   display: flex;
  // }
}

.topbar-nav__item {
  padding: 0 18px;
  display: flex;
  align-items: center;
  @media (max-width: $breakpoint-sm) {
    padding: 0px;
  }
}

.topbar-nav__title {
  padding-top: 8px;
  display: flex;
}

.topbar-nav__icon-container {
  padding-top: 4px;
}

.topbar-nav__item-link {
  text-transform: uppercase;
  font-weight: bold;
  color: #4e525f;
  font-size: 11px;
  text-decoration: none;
  letter-spacing: 1px;
}

.topbar-nav__item-custom-link {
  text-transform: uppercase;
  font-weight: bold;
  color: $color-brand-primary;
  font-size: 11px;
  text-decoration: none;
  letter-spacing: 1px;
}

.topbar-nav__item--black {
  color: black;
  padding-top: 1px;
}

.topbar-nav__item-highlight {
  position: relative;
}

.topbar-nav__item-highlight:hover {
  a {
    color: black;
  }
}

.topbar-right {
  padding-top: 8px;
  display: flex;
  align-items: center;
}

.topbar-right__user-name {
  font-size: 12px;
  color: #b1b1b1;
  cursor: pointer;
  font-weight: bold;
  margin: 5px 5px 0 5px;
}

.topbar-right__search {
  background-color: transparent;
  border: none;
}

.topbar-right__login {
  width: 164px;
  height: 40px;
  padding-right: 20px;
  border: none;
  border-radius: 4px;
  background-image: linear-gradient(64deg, #3a3b42, #525461);
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-left: 20px;

  .material-icons {
    margin-right: 10px;
    font-size: 11px;
    vertical-align: middle;
    color: #a5abbf;
  }
}

.topbar-right__register {
  width: 164px;
  height: 40px;
  border: none;
  margin-left: 20px;
  border-radius: 4px;
  background: none;
  font-size: 12px;
  font-weight: bold;
  color: #4e525f;
  text-transform: uppercase;
  letter-spacing: 1px;

  .material-icons {
    margin-right: 10px;
    font-size: 11px;
    vertical-align: middle;
    color: #a5abbf;
  }
}

.userinfo {
  margin-left: 30px;
  font-size: 14px;
  font-weight: bold;
  color: #4e525f;
  text-transform: uppercase;
  cursor: pointer;

  &:hover .userinfo-menu {
    display: block;
  }
}

.userinfo-menu {
  display: none;
  position: absolute;
  right: 0.8rem;
  background-color: #fff;
  box-sizing: border-box;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  padding: 12px 32px;
  z-index: 1;
  border-radius: 0 0 4px 4px;

  span {
    display: block;
    cursor: pointer;
    margin: 18px 0;
    letter-spacing: 1px;

    color: #aaa;
    &:hover {
      color: #4e525f;
    }
  }
}
