@import '../../../../../styles/core';

.tile-list {
  position: relative;
  width: 60%;
  max-width: 60%;
  margin: 8px auto;
  background-color: $tile-background;
  border-radius: 8px;
  border: none;
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 42px;
  @media (max-width: $breakpoint-sm) {
    width: 95%;
    max-width: 95%;
  }
  @media (min-width: $breakpoint-xl) {
    width: 40%;
    max-width: 40%;
  }
}
.tile-list__padding {
  padding: 5px;
}

.title-list__voting {
  margin-top: 50px;
  height: 100px;
  background-color: map-get($color-greyscale, 'light');
}
