@import '../../styles/core';

.rss-wrapper {
  position: relative;
  width: 60%;
  max-width: 60%;
  margin: 8px auto;
  display: flex;
  flex-direction: column;
  gap: 40px;
  @media (max-width: $breakpoint-md) {
    width: 95%;
    max-width: 95%;
  }
  @media (min-width: $breakpoint-xl) {
    width: 40%;
    max-width: 40%;
  }
}
