@import '../../../../../styles/_core';

.tile-category {
  padding: 0 30px;
  height: 30px;
  display: flex;
  justify-content: flex-start;
}

.tile-category__text {
  color: map-get($color-greyscale, 'dark');
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.6px;
  font-family: $main-font;
  width: 80%;
}

.tile-category__icon {
  color: map-get($color-greyscale, 'regular');
  height: 16px;
  width: 20px;
  margin-right: 6px;
}
