@import '../../../../styles/_core.scss';

.register {
  box-sizing: border-box;
  padding: 30px 30px 20px 30px;
  width: 300px;
  border-radius: 8px;
  background-color: $color-white;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 50%;
  margin-left: -150px;
  margin-top: -200px;
  left: 50%;
  z-index: 100;
}

.register__topbar {
  height: 14px;
  position: relative;
}

.register__logo {
  width: auto;
  height: 100%;
}

.register__icon-right {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 15px !important;
  cursor: pointer;
}

.register__header {
  margin-top: 30px;
}

.register__error {
  color: #ff0000;
  margin-top: 10px;
  font-size: 11px;
}

.form-group {
  margin-top: 34px;
}

.register__input {
  border: none;
  padding: 6px 0;
  box-sizing: border-box;
  display: block;
  width: 100%;
  outline: none;
  letter-spacing: 0.6px;
  color: map-get($color-greyscale, 'dark');
  box-shadow: 0 2px map-get($color-greyscale, 'regular');
  font-family: $font-default, sans-serif;
  &:focus {
    box-shadow: 0 2px #525461;
  }
  &::placeholder {
    padding: 6px 0;
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 1px;
    color: map-get($color-greyscale, 'regular');
    font-family: $font-default, sans-serif;
  }
}

.register__lost-pass {
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 1px;
  color: map-get($color-greyscale, 'regular');
  text-transform: uppercase;
  text-align: right;
  margin: 8px 0;
  float: right;
  text-decoration: none;
}

.register__buttons button,
.register__buttons a {
  width: 100%;
  margin-top: 8px;
}

.register__backdrop {
  background: $color-black;
  opacity: 0.5;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}
.g-recaptcha {
  transform: scale(0.77);
  -webkit-transform: scale(0.77);
  transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
}
