@import '../../../../../styles/_core';

.list-tile-vote__button-container {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  padding: 30px;
  gap: 20px;
}

.list-tile-vote__button {
  box-sizing: border-box;
  margin: 0 4px;
  padding: 0;
  background-color: $tile-background;
  height: 40px;
  border-radius: 4px;
  border: none;
  font-size: 18px;
  font-weight: medium;
  font-family: $main-font;
  color: map-get($color-greyscale, 'dark');
  letter-spacing: 0.6px;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.1);
}

.list-tile-vote__button:focus {
  outline: 0;
}

.list-tile-vote__button::-moz-focus-inner {
  border: 0;
}
