@import '../../styles/core';

.dropdown-wrapper {
  background-color: white;
  padding: 5px 20px;
  border-radius: 4px;
  border: none;
  min-width: 200px;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
  margin: 10px;
  @media (max-width: $breakpoint-sm) {
    min-width: 100px;
  }
}
.dropdown-icon {
  margin-right: 10px;
  font-size: 18px !important;
  vertical-align: top;
}
