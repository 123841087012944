@import '../../styles/core';

.card {
  margin: 8px auto;
  background-color: $tile-background;
  border-radius: 8px;
  border: none;
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.1);

  @media (min-width: $breakpoint-sm) {
    padding: 40px;
    width: 300px;
  }

  @media (max-width: $breakpoint-sm) {
    width: 85%;
    padding: 20px;
  }
}
