@import '../../../../styles/_core.scss';

.login {
  box-sizing: border-box;
  padding: 30px 30px 20px 30px;
  width: 300px;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 50%;
  margin-left: -150px;
  margin-top: -200px;
  left: 50%;
  z-index: 100;
}

.login__header {
  margin-top: 30px;
}

.login__topbar {
  height: 14px;
  position: relative;
}

.login__logo {
  width: auto;
  height: 100%;
}

.login__icon-right {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 15px !important;
  cursor: pointer;
}

.login__error {
  color: #ff0000;
  margin-top: 10px;
  font-size: 11px;
}

.login__lost-pass {
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 1px;
  color: map-get($color-greyscale, 'regular');
  text-transform: uppercase;
  text-align: right;
  margin: 8px 0;
  float: right;
  text-decoration: none;
}

.login__buttons button,
.login__buttons a {
  width: 100%;
  margin-top: 8px;
}

.login__backdrop {
  background: $color-black;
  opacity: 0.5;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}
