@import '../../../styles/core';

.dashboard {
  display: flex;
  justify-content: space-around;
  margin-bottom: -70px;
  padding: 40px 0;
}
.dashboard__profile {
  display: flex;
  align-items: center;
}
.dashboard__greetings {
  font-size: 24px;
  font-weight: bold;
}
.dashboard__buttons {
  display: flex;
  align-items: center;
  justify-content: center;
}
