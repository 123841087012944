@import '../../styles/_core.scss';

.app_container {
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 45px);
  background-color: map-get($color-greyscale, 'default');
}

.app_content {
  width: 90%;
  display: flex;
  flex-direction: column;
}

.fullApp {
  transition: all 0.1s ease;
  -webkit-filter: blur(0) brightness(1);
  filter: blur(0) brightness(1);
  transform: scale(1);
}

.fullApp.blur {
  -webkit-filter: blur(8px);
  filter: blur(8px);
}
