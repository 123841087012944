@import '../../../styles/core';

.user-photo {
  margin-right: 20px;
  display: inline-block;
  vertical-align: middle;

  img {
    width: 100%;
    height: auto;
    box-shadow: 0 0 0 4px #ddd;
    border-radius: 100px;
  }
}

.user-photo--small {
  width: 50px;
}
.user-photo--big {
  width: 80px;

  img {
    box-shadow: 0 0 0 6px #fff;
  }
}
