@import '../../../../../styles/_core';

.tile-claim-container {
  width: 100%;
}
.tile-claim {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin: 0 30px 0 40px;
  padding-top: 5px;
  position: relative;
}

.tile-claim__quote {
  font-family: $main-font;
  font-weight: 500;
  font-style: italic;
  font-size: 50px;
  position: absolute;
  color: map-get($color-greenscale, 'default');
  left: -14px;
  top: 8px;
}

.close-quote {
  left: auto;
  top: auto;
}

.tile-claim__text {
  font-size: 18px;
  line-height: 1.5;
  color: map-get($color-greyscale, 'dark');
  font-family: $claims-font;
  padding-left: 0.5rem;
}
