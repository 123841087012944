@import '../../../../styles/core';

.tile {
  position: relative;
  width: 312px;
  max-width: 312px;
  margin: 8px auto;
  background-color: $tile-background;
  border-radius: 8px;
  border: none;
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.1);
}

.tile--single-claim-view {
  @media (min-width: $breakpoint-sm) {
    width: 500px;
    max-width: 500px;
  }
}

.tile--list-view {
  width: 100%;
  margin: 20px auto;
}

.tile__break-line {
  margin: auto;
  border: none;
  height: 1px;
  background-color: map-get($color-greyscale, 'light');
  width: 80%;
}

.tile__container--list-mview {
  display: flex;
}

.tile__left-column--list-view {
  display: flex;
  flex-direction: column;
  width: 70%;
}

.tile__right-column--list-view {
  display: flex;
  flex-direction: column;
  width: 30%;
  justify-content: center;
}

.tile__vertical-line {
  border-left: 1px solid #b1b1b1;
}
