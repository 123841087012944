@import '../../../styles/core';
.dashboard__buttons {
  .dashboard-button {
    border-radius: 10px;
    margin: 0 10px;
    box-sizing: border-box;
    padding: 20px 40px;
    transition: all 0.2s ease;
    display: inline-block;
    cursor: pointer;
    color: map-get($color-greyscale, 'dark');

    &:hover {
      background: #e9e9e9;
    }
  }
  .dashboard-button__icon {
    color: map-get($color-greyscale, 'regular');
  }
  .dashboard-button__numbers {
    font-size: 24px;
    font-weight: bold;
    vertical-align: top;
    display: inline-block;
    padding-left: 10px;
  }
  .dashboard-button__desc {
    margin: 6px 0;
    display: inline-block;
  }

  .active {
    background: #ddd;
    pointer-events: none;

    .dashboard-button__icon {
      color: map-get($color-greyscale, 'dark');
    }
  }
}
