@import '../../../../../styles/_core';

.tile-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.tile-header__first-section {
  box-sizing: border-box;
  width: 100%;
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tile-header__first-section__group-icon {
  color: map-get($color-greyscale, 'regular');
}

.tile-header__first-section__text {
  font-family: $main-font;
  font-weight: 500;
  font-size: 14px;
  color: map-get($color-greyscale, 'dark');
  margin-left: 4px;
  letter-spacing: 0.6px;
}

.tile-header__first-section__time {
  font-family: $main-font;
  font-weight: 500;
  font-size: 10px;
  color: map-get($color-greyscale, 'regular');
  letter-spacing: 0.8px;
}

.tile-header__second-section {
  box-sizing: border-box;
  width: 100%;
  padding: 7px 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tile-header__second-section__result-wrapper {
  display: flex;
  align-items: center;
}
.tile-header__second-section__result-wrapper__tie {
  display: flex;
  & :first-child {
    margin-right: 0;
  }
}
.tile-header__second-section__text {
  text-transform: uppercase;
  font-family: $main-font;
  font-weight: bold;
  font-size: 26px;
  letter-spacing: 1.7px;
  color: map-get($color-greyscale, 'dark');
  width: 60%;
}

.tile-header__second-section__star-icon {
  color: map-get($color-greyscale, 'regular');
  font-size: 23px;
}

.tile-header__second-section__share-icon {
  color: map-get($color-greyscale, 'regular');
  font-size: 23px;
  cursor: pointer;
}
