@import '../../../../../styles/_core';

.list-tile-voted-panel-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 0 30px;
}
.list-tile-voted-panel__header {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  .list-tile-voted-panel__header-icon {
    color: map-get($color-greyscale, 'regular');
    margin-right: 5px;
    width: 14px;
    height: 14px;
  }
}
.list-tile-vote__title {
  font-size: 16px;
}
.list-title-voted-panel__result {
  display: flex;
  margin-left: 10px;
}
.list-title-voted-panel__result-text {
  text-transform: uppercase;
  font-family: $main-font;
  font-size: 16px;
  letter-spacing: 1.75px;
  line-height: 28px;
  width: 60%;
}
.green {
  color: map-get($color-greenscale, 'default');
}
.red {
  color: map-get($color-redscale, 'default');
}
.list-title-voted-panel__time {
  margin-left: 20px;
  display: flex;
  align-items: center;
  gap: 8px;
}
.list-title-voted-panel__time__icon {
  color: map-get($color-greyscale, 'regular');
}
.list-title-voted-panel__time__text {
  font-weight: 500;
  font-size: 14px;
  color: map-get($color-greyscale, 'dark');
  letter-spacing: 0.8px;
}

.list-tile-voted-panel__buttons-wrapper {
  display: flex;
  gap: 16px;
}
