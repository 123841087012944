// --------------------------------------------------
//	Typography
// --------------------------------------------------

$font-default: 'Archivo';
$font-special: '';

$font-size-default: '14px';

.text--small {
  font-size: 10px;
}

.text--medium {
  font-size: 12px;
}

.text--regular {
  font-size: 14px;
}

.text--big {
  font-size: 18px;
}

.text--large {
  font-size: 26px;
}
