@import '../../styles/core';

.settings-header__title {
  font-family: $main-font;
  font-weight: bold;
  font-size: 26px;
  letter-spacing: 1.7px;
  color: map-get($color-greyscale, 'dark');
  width: 60%;
}

.settings-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}
