@import '../../../../../styles/_core';

.tile-rate {
  margin: 30px 30px 20px 30px;
  display: flex;
  justify-content: center;
  position: relative;
}

.tile-rate__progress-bar {
  width: 100%;
  border-radius: 4px;
}

.tile-rate__bar--light-green {
  stroke: map-get($color-greenscale, 'light');
  fill: map-get($color-greenscale, 'light');
  border: none;
  stroke-width: 8;
}

.tile-rate__bar--light-red {
  stroke: map-get($color-redscale, 'light');
  fill: map-get($color-redscale, 'light');
  border: none;
  stroke-width: 8;
}

.tile-rate__bar--green {
  stroke: map-get($color-greenscale, 'default');
  fill: map-get($color-greenscale, 'default');
  border: none;
  stroke-width: 8;
}

.tile-rate__bar--red {
  stroke: map-get($color-redscale, 'default');
  fill: map-get($color-redscale, 'default');
  border: none;
  stroke-width: 8;
}

.tile-rate__result {
  font-size: 26px;
  color: $tile-background;
  fill: $tile-background;
  font-family: $main-font;
  font-weight: bold;
  position: absolute;
}

.tile-rate__percent {
  font-size: 14px;
  color: $tile-background;
  fill: $tile-background;
  letter-spacing: 0.3px;
  font-family: $main-font;
  font-weight: bold;
  position: absolute;
  left: 105%;
  top: 4px;
}
