@import '../../styles/_core.scss';

.btn {
  min-height: 46px;
  min-width: 170px;
  border: none;
  padding: 0 30px;
  margin: 0 4px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  outline: none;
  transition: all 0.2s ease;

  &:disabled {
    opacity: 0.6;
  }

  @media (max-width: $breakpoint-sm) {
    min-height: 40px;
    min-width: 90px;
    padding: 0 10px;
    font-size: 10px;
  }
}

.btn__link {
  display: inline-block;
}

.btn--dark {
  color: $color-white;
  background-color: map_get($color-greyscale, 'dark');
  background-image: linear-gradient(64deg, #3a3b42, #525461);

  &:hover {
    background: map_get($color-greyscale, 'dark');
  }
}

.btn--light {
  color: map-get($color-greyscale, 'dark');
  background: none;

  &:hover {
    background: map_get($color-greyscale, 'light');
  }
}

.disabled {
  pointer-events: none;
}

i.btn__icon {
  margin-left: -14px;
  margin-right: 10px;
  font-size: 11px;
  vertical-align: middle;
  color: map-get($color-greyscale, 'regular');
}

.btn--white {
  background-color: $color-white;
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.1);
}
