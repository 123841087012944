@import '../../styles/core';

.settings {
  margin-top: 50px;

  @media (min-width: $breakpoint-md) {
    width: 60%;
    padding: 10px 0;
    margin: 0 auto;
  }

  @media (min-width: $breakpoint-sm) {
    padding: 55px 0;
  }
}

.settings-cards-view {
  list-style: none;
  font-family: Arial, Helvetica, sans-serif;
  width: 100%;
  margin: 0 auto;
}
