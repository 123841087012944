@import '../../../../../styles/_core';

.tile-vote {
  padding: 20px 15px;
}

.tile-vote__title {
  color: map-get($color-greyscale, 'dark');
  font-size: 14px;
  font-weight: 500;
  font-family: $main-font;
  letter-spacing: 0.6px;
}

.tile-vote__icon {
  color: map-get($color-greyscale, 'regular');
  margin-left: 12px;
  margin-right: 5px;
  width: 12px;
  height: 12px;
}

.tile-vote__button-container {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  padding: 0 4px;
  height: 60px;
  border-radius: 4px;
  background-color: map-get($color-greyscale, 'light');
}

.tile-vote__button {
  box-sizing: border-box;
  margin: 0 4px;
  padding: 0;
  background-color: $tile-background;
  height: 40px;
  border-radius: 4px;
  border: none;
  font-size: 14px;
  font-weight: medium;
  font-family: $main-font;
  color: map-get($color-greyscale, 'dark');
  letter-spacing: 0.6px;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.tile-vote__button:focus {
  outline: 0;
}

.tile-vote__button::-moz-focus-inner {
  border: 0;
}
