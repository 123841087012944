@import '../../../styles/core';

.about-card-wrapper {
  font-family: $main-font;
  background-color: $tile-background;
  border-radius: 8px;
  border: none;
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.1);
}

.about-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    font-size: 12px;
    color: map-get($color-greyscale, 'regular');
  }
}

.about-paragraph {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 40px;
}
.about-info-section {
  padding: 40px;
}
