html,
body {
  height: 100%;
}

body {
  @extend %u-font--default;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  color: $color-grey;
  font-size: 14px;
}

ul {
  margin: 0;
  padding: 0;
}
