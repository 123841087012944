@import '../../../../../styles/core';

.tile__collapse {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
  background-color: map-get($color-greyscale, 'light');
}

.tile__controls {
  padding: 15px 10px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
