@import '../../../../styles/core';

.view-switch {
  display: none;
  margin-left: 6px;

  @media (min-width: $breakpoint-md) {
    display: flex;
  }
}
.view-switch__item {
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 0.2s ease;
}
.view-switch__item.active {
  opacity: 1;
}
