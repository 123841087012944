@import '../../styles/_core.scss';

.home {
  display: flex;
  justify-content: space-between;
}

.footer {
  position: fixed;
  padding: 10px 10px 0px 10px;
  bottom: 0;
  width: 100%;
  height: 40px;
}
