@import '../../../../../styles/_core';

.list-tile-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.list-tile-header__first-section {
  box-sizing: border-box;
  width: 100%;
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: $breakpoint-sm) {
    flex-direction: column;
    padding: 0 30px;
    gap: 8px;
    align-items: flex-start;
  }
}
.list-title-header__first-section__votes {
  display: flex;
  align-items: center;
  gap: 4px;
}
.list-tile-header__first-section__group-icon {
  color: map-get($color-greyscale, 'regular');
  font-size: 25px;
}

.list-tile-header__first-section__text {
  font-family: $main-font;
  font-weight: 500;
  font-size: 18px;
  line-height: 15px;
  color: map-get($color-greyscale, 'dark');
  margin-left: 4px;
  letter-spacing: 0.6px;
}
.list-tile-header__first-section__result-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 25px;
}

.list-title-header__second-section-info {
  font-family: $main-font;
  display: flex;
  gap: 8px;
  font-size: 16px;
  letter-spacing: 0.8px;
  color: map-get($color-greyscale, 'regular');
}
.list-tile-header__second-section__time {
  font-weight: 500;
  font-size: 16px;
  color: map-get($color-greyscale, 'dark');
  letter-spacing: 0.8px;
}
.list-tile-header__first-section__star-icon {
  color: map-get($color-greyscale, 'regular');
  font-size: 23px;
}

.list-tile-header__first-section__share-icon {
  color: map-get($color-greyscale, 'regular');
  font-size: 25px;
  cursor: pointer;
}

.list-tile-header__second-section {
  box-sizing: border-box;
  width: 100%;
  padding: 18px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: $breakpoint-sm) {
    flex-direction: column;
    padding: 0 30px;
    align-items: flex-start;
  }
}

.list-tile-header__second-section__result-wrapper__tie {
  display: flex;
  & :first-child {
    margin-right: 0;
  }
}

.list-tile-header__second-section__result-wrapper {
  display: flex;
  align-items: center;
  @media (max-width: $breakpoint-sm) {
    padding-top: 20px;
    padding-bottom: 10px;
  }
}
.list-tile-header__second-section__text {
  text-transform: uppercase;
  font-family: $main-font;
  font-weight: bold;
  font-size: 26px;
  letter-spacing: 1.75px;
  line-height: 28px;
  color: map-get($color-greyscale, 'dark');
  width: 60%;
}
