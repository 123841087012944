@import '../../../../../styles/_core';

.icon__outer-circle--green {
  @extend %icon__outer-circle;
  background-color: map-get($color-greenscale, 'light');
}

.icon__inner-circle--green {
  @extend %icon__inner-circle;
  background-color: map-get($color-greenscale, 'default');
}

.icon__check-icon {
  @extend %icon;
}
