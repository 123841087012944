@import '../../styles/core';

.source-wrapper {
  padding: 0 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
}

.source__icon {
  color: map-get($color-greyscale, 'dark');
  height: 20px;
  width: 20px;
}

.source__link {
  color: map-get($color-greyscale, 'dark');
  font-size: 14px;
  letter-spacing: 0.8px;
  font-family: $main-font;
  font-weight: 500px;
}
