@import '../../styles/_core.scss';

.single-claim-container {
  padding-top: 100px;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  .single-claim__bg-label-wrapper {
    display: flex;
    justify-content: center;

    .single-claim__bg-label-text {
      flex: 1;
      font-size: 206px;
      color: map-get($color-greyscale, 'dark');
      opacity: 0.1;
      text-align: center;
      text-transform: uppercase;

      position: absolute;
      top: 35px;
    }
  }
  .single-claim__deleted {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: $main-font;
    a {
      color: map-get($color-greenscale, 'default');
    }
  }
}

.single-claim__related-claims-label-wrapper {
  display: flex;
  align-items: center;
  .single-claim__related-claims-icon {
    width: 24px;
    height: 24px;
    margin-right: 5px;
  }
  .single-claim__related-claims-text {
    font-size: 18px;
    font-weight: bold;
  }
}

.loading {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .MuiCircularProgress-colorPrimary {
    color: map-get($color-greyscale, 'dark');
  }
}
