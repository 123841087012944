@import '../../../../styles/core';
.feed-header {
  width: 96%;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  margin-bottom: 18px;
}
.feed-header__title {
  font-size: 18px;
  font-weight: bold;
}
.feed-header_title-icon {
  color: #a5abbf;
  vertical-align: middle;
}
.feed-header_filter-name {
  margin-left: 6px;
}
