@import '../../styles/core';

.claims {
  width: 100%;
  padding: 10px 0;

  @media (min-width: $breakpoint-sm) {
    padding: 55px 0;
  }
}
