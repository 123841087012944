@each $current-size in $icon-size {
  .icon--size-#{nth($current-size, 1)} {
    font-size: nth($current-size, 2);
  }
}

@each $current-color in $icon-color {
  .icon--color-#{nth($current-color, 1)} {
    color: nth($current-color, 2);
  }
}

%icon__outer-circle {
  height: 24px;
  border-radius: 50%;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

%icon__inner-circle {
  border-radius: 50%;
  height: 16px;
  width: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

%icon {
  color: $tile-background;
  height: 12px;
  width: 12px;
}
