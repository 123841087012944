@import '../../../styles/core';

.rsscard-wrapper {
  font-family: $main-font;
  background-color: $tile-background;
  border-radius: 8px;
  border: none;
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.1);
}

.rsscard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    font-size: 12px;
    color: map-get($color-greyscale, 'regular');
  }
}

.rsscard-paragraph {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 40px;
}
.rsscard-info-section {
  padding: 40px;
}
.rsscard-button-section {
  border-top: 1px solid '#E8E9EC';
  margin-top: 20px;
  height: 100px;
  background-color: map-get($color-greyscale, 'light');
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px 0 40px;
  @media (max-width: $breakpoint-md) {
    padding: 0 10px 0 10px;
  }
}

.rsscard-button-category {
  display: flex;
  align-items: center;
  button {
    height: 46px;
    @media (max-width: $breakpoint-sm) {
      min-height: 40px;
      min-width: 90px;
      padding: 0 10px;
      font-size: 10px;
    }
  }
}
