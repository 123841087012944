@import '../../../../../styles/_core';

.tile-source {
  padding: 0 30px;
  height: 30px;
  display: flex;
  justify-content: space-between;
}

.tile-source__text {
  color: map-get($color-greyscale, 'dark');
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.6px;
  font-family: $main-font;
}

.tile-source__icon {
  color: map-get($color-greyscale, 'regular');
  height: 16px;
  width: 20px;
  margin-right: 6px;
}

.tile-source__link {
  padding-top: 2px;
  display: flex;
  justify-content: space-between;
  text-decoration: none;
  color: map-get($color-greyscale, 'regular');
  font-size: 10px;
  letter-spacing: 0.8px;
  font-family: $main-font;
  font-weight: 500px;
}
