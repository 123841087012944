@import '../../styles/_core.scss';

.form-input {
  border: none;
  padding: 6px 0;
  box-sizing: border-box;
  display: block;
  width: 100%;
  background: transparent;
  z-index: 100;
  position: relative;
  outline: none;
  letter-spacing: 0.6px;
  color: map-get($color-greyscale, 'dark');
  box-shadow: 0 2px map-get($color-greyscale, 'regular');
  font-family: $font-default, sans-serif;

  &:focus {
    box-shadow: 0 2px #525461;
  }
}

.form-control {
  position: relative;
  padding: 10px 0;
  margin: 20px 0;
}

.form-label {
  user-select: none;
  position: absolute;
  top: 20px;
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 1px;
  font-weight: bold;
  transition: all 0.2s ease;
  opacity: 0.5;
}

.error {
  margin-top: 10px;
  display: block;
  color: #ff0000;
  font-size: 11px;
}

.form-input:focus + .form-label,
.form-input:not([value='']) + .form-label {
  transform: translate3d(0, -180%, 0);
}
