@import '../../../../../styles/_core';

.tile-voted-panel__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .tile-voted-panel__header-icon {
    color: map-get($color-greyscale, 'regular');
    margin-right: 5px;
    width: 12px;
    height: 12px;
  }
}

.tile-voted-panel-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px;
  padding: 10px;
  height: 70px;
  border-radius: 4px;
  background-color: map-get($color-greyscale, 'light');
}
.tile-voted-panel__button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $tile-background;
  width: 50%;
  height: 40px;
  border-radius: 4px;
  border: none;
  font-size: 14px;
  font-weight: medium;
  font-family: $main-font;
  color: $color-white;
  letter-spacing: 0.6px;
}

.truth {
  background-color: map-get($color-greenscale, 'default');
}

.bullshit {
  background-color: map-get($color-redscale, 'default');
}

.tile-voted-panel__controls-container {
  display: flex;
  justify-content: space-between;
  margin: 10px 10px 0;
  width: 100%;
}

.tile-voted-panel__buttons-wrapper {
  width: 90px;
  display: flex;
  justify-content: space-between;
}

.tile-voted-panel__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: $color-white;
  border-radius: 4px;
  cursor: pointer;
  font-size: 20px;
  svg {
    color: map-get($color-greyscale, 'regular');
  }
}
